import React from 'react'
import "./History.scss";
import { ImQuotesLeft as Quotes } from "react-icons/im"
import { ImQuotesRight as Quotes2 } from "react-icons/im"

const History = () => {
    return (
        <section id="aboutus-history" className='section-space'>
            <div className='app-container'>
                <h2 className='font-size-64 font-mst-b mb-4'>Istoric</h2>
                <div className='quotes-container d-flex align-items-center justify-content-center w-100 flex-column gap-3'>
                    <div className='quote font-size-24 font-mst-b d-flex align-items-center justify-content-center gap-1 p-3'>
                        <Quotes /> Per aspera ad astra <Quotes2 />
                    </div>
                    <h4 className='font-size-20 font-mst-r text-center mb-0 px-4'>Firma TanTranschem își desfășoară activitatea de distribuire îngrășăminte chimice în județul Ialomița, orașul Slobozia din anul 2008  până în prezent. Având o gamă variată de produse precum azotat de amoniu, uree, complexe poate satisface piața cu servicii de calitate.
                        Avem locuri special amenajate pentru depozitarea produselor, astfel încât acestea să nu fie dispuse la deterioare.
                        <br />NOU ! Ambalare și distribuire proprie la saci de 40 – 50 kg !</h4>
                </div>
            </div>
        </section>
    )
}

export default History