import React, { useRef, useState } from 'react'
import "./Carousel.scss";
import Slider from 'react-slick';
import { BiRightArrowCircle as RightArrow } from "react-icons/bi"
import { BiLeftArrowCircle as LeftArrow } from "react-icons/bi"

const Carousel = () => {

    const [index, setIndex] = useState(0)

    const settings = {
        className: "center",
        arrows: false,
        autoplaySpeed: 2000,
        draggable: true,
        variableWidth: true,
        speed: 750,
        centerMode: true,
        infinite: true,
        beforeChange: (prev, next) => { setIndex(next) },
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                },
            }
        ],
    };

    console.log(index)

    const sliderRef = useRef(null)

    return (
        <section id="homepage-carousel" className='section-space'>
            <div className='app-container'>
                <h2 className='font-size-64 font-mst-b mb-4'>Obiective</h2>
                <Slider {...settings} ref={sliderRef}>
                    <div className={`carouse-item position-relative d-flex align-items-center justify-content-center flex-column ${index === 0 ? "slide-active" : ""}`}>
                        <div className='objective position-absolute font-size-32 font-msb-r color-white'>
                            Ambalare proprie
                        </div>
                        <div className='objective-detail px-3 text-center position-absolute font-size-24 font-msb-r color-white'>
                            Ambalare proprie la <span className='color-red'>saci mici</span> de <span className="color-red">40 – 50 kg</span> în funcție de produsul dorit, pentru satisfacerea tuturor clienților cu nevoi diferite!
                        </div>
                        <img src={"https://picsum.photos/500/500?random=12"} alt='carousel-img' className='img-fluid carousel-img' />
                    </div>
                    <div className={`carouse-item position-relative d-flex align-items-center justify-content-center ${index === 1 ? "slide-active" : ""}`}>
                        <div className='objective position-absolute font-size-32 font-msb-r color-white'>
                            Clienți fericiți
                        </div>
                        <div className='objective-detail px-3 text-center position-absolute font-size-24 font-msb-r color-white'>
                            Pentru satisfacerea clienților noștri produsele sunt depozitate într-un mediu optim, ferit de factori externi dăunători.

                        </div>
                        <img src={"https://picsum.photos/500/500?random=123"} alt='carousel-img' className='img-fluid carousel-img' />
                    </div>
                    <div className={`carouse-item position-relative d-flex align-items-center justify-content-center ${index === 2 ? "slide-active" : ""}`}>
                        <div className='objective position-absolute font-size-32 font-msb-r color-white'>
                            Timp bun de livrare
                        </div>
                        <div className='objective-detail px-3 text-center position-absolute font-size-24 font-msb-r color-white'>
                            Timpul de livrare fără întârziere astfel încât produsele noastre sa ajungă la client în ziua stabilită.
                        </div>
                        <img src={"https://picsum.photos/500/500?random=11"} alt='carousel-img' className='img-fluid carousel-img' />
                    </div>
                </Slider>
                <div className='mt-4 btn-container font-size-24 font-mst-b main-dark-green-color d-flex align-items-center justify-content-center gap-3'>

                    <button onClick={() => sliderRef.current.slickPrev()}>
                        <LeftArrow />
                    </button>
                    <button onClick={() => sliderRef.current.slickNext()}>
                        <RightArrow />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Carousel