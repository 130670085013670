import React from 'react'
import { Accordion } from 'react-bootstrap';
import "./FAQ.scss";

const accordionData = [{
    q: "În cât timp va ajunge comanda de peste 500kg?",
    a: <>Comanda dvs. va ajunge în <strong>3/4 zile lucrătoare</strong> dacă este <strong>peste</strong> 500kg.</>
},
{
    q: "În cât timp va ajunge comanda sub 500kg?",
    a: <>Comanda dvs. va ajunge în <strong>2/3 zile lucrătoare</strong> prin curier dacă este <strong>sub</strong> 500kg.</>
}
]

const FAQ = () => {
    return (
        <section id="aboutus-faq" className='section-space'>
            <div className='app-container'>
                <h2 className='font-size-64 font-mst-b mb-4'>FAQ</h2>
                <div className='w-100 accordion-div'>
                    <Accordion defaultActiveKey="0">
                        {
                            accordionData.map((el, i) => {
                                return <Accordion.Item key={"acc-" + i} eventKey={`${i}`}>
                                    <Accordion.Header>
                                        <span className='font-mst-b font-size-24'>
                                            {el.q}
                                        </span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <span className='font-mst-r font-size-20'>
                                            {el.a}

                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                            })

                        }
                    </Accordion>
                </div>
            </div>
        </section>
    )
}

export default FAQ