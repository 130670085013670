import React, { useEffect } from 'react'
import "./Homepage.scss";
import { Helmet } from 'react-helmet'
import CardsSection from './CardsSection/CardsSection'
import Carousel from './Carousel/Carousel'
import Hero from './Hero/Hero'
import Oferta from './Oferta/Oferta'
import { useLocation } from 'react-router-dom';

const Homepage = () => {

  return (
    <>
      <Helmet>
        <title>TAN | Acasa</title>
      </Helmet>
      <div id="homepage">
        <Hero />
        {/* <CardsSection /> */}
        <Carousel />
        <Oferta />
      </div>
    </>
  )
}

export default Homepage