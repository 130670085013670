import React from 'react'
import "./Hero.scss"
import { Col, Row } from 'react-bootstrap'
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai"
import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai"
import Img1 from "../../../Assets/png/TAN.png"
import { useNavigate } from 'react-router-dom'

const Hero = () => {

    const navigate = useNavigate()

    return (
        <section id="homepage-hero" className='section-space'>
            <div className='app-container position-relative'>
                <div className='banner-container text-start font-size-24 font-mst-b position-absolute'>
                    NOU ! <br />
                    Saci de 40 – 50 kg !!! <br />
                    Comandă acum ! Stoc limitat !

                </div>
                <Row xs={1} sm={1} md={1} lg={2}>
                    <Col className='order-2 order-lg-1'>
                        <div className='d-flex align-items-center align-items-lg-start justify-content-center h-100 flex-column'>
                            <h2 className='font-size-80 font-mst-b text-center text-lg-start'>Tan Transchem</h2>
                            <h4 className='font-size-24 font-mst-b text-center text-lg-start'>– distribuitor îngrășăminte chimice – </h4>
                            <div className='btns-container flex-column-reverse flex-sm-row mt-3 d-flex align-items-center justify-content-center gap-3'>
                                <button onClick={() => navigate("/despre")} className='main-btn d-flex align-items-center justify-content-center gap-2'>
                                    <span className='font-size-20 font-mst-r'>Despre noi</span>
                                    <ArrowDown />
                                </button>
                                <button onClick={() => navigate("/contact")} className='main-btn d-flex align-items-center justify-content-center gap-2'>
                                    <span className='font-size-20 font-mst-r' >Contactează-ne</span>
                                    <ArrowRight />
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col className='order-1 order-lg-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src={Img1} alt='hero-img' className='img-fluid hero-img' />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Hero