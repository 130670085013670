import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./Components/Navbar/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import ScrollToTop from "./Components/ScrollTop/ScrollToTop";
import { APP_ROUTES } from "./Constants/constants";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Contact from "./Pages/Contact/Contact";
import Errorpage from "./Pages/Errorpage/Errorpage";
import Homepage from "./Pages/Homepage/Homepage";
import GATracking from "./Components/GATracking"
import "./Styles/main.scss";

function App() {

  GATracking();


  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <>
      <ScrollToTop />
      <div className="App">

        <Navbar />

        <Routes>
          <Route path={APP_ROUTES.homepage} element={<Homepage />} />
          <Route path={APP_ROUTES.about} element={<AboutUs />} />
          <Route path={APP_ROUTES.contact} element={<Contact />} />
          <Route path={APP_ROUTES.errorPage} element={<Errorpage />} />
        </Routes>

        <Footer />
      </div>
    </>
  );
}

export default App;
