import React, { useState } from 'react'
import "./Navbar.scss";
import Logo from "../../Assets/png/logo1.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Constants/constants';

const Navbar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [expand, setExpand] = useState(false)

    const handleNavigate = (route) => {
        setExpand(false)
        navigate(route)
        window.scrollTo(0, 0)
    }

    return (
        <nav id="navbar">
            <div className='app-container d-flex align-items-center justify-content-between'>
                <div className='logo-container' onClick={() => navigate(APP_ROUTES.homepage)}>
                    <img src={Logo} alt="main logo" className='img-fluid main-logo pointer' />
                </div>

                <div className='routes-container d-none d-sm-flex gap-5 align-items-center justify-content-center'>
                    <span className={`font-size-24 font-mst-b pointer main-dark-green-color ${location.pathname === APP_ROUTES.homepage ? "active" : ""}`} onClick={() => navigate(APP_ROUTES.homepage)}>Acasă</span>
                    <span className={`font-size-24 font-mst-b pointer main-dark-green-color ${location.pathname === APP_ROUTES.about ? "active" : ""}`} onClick={() => navigate(APP_ROUTES.about)}>Despre</span>
                    <span className={`font-size-24 font-mst-b pointer main-dark-green-color ${location.pathname === APP_ROUTES.contact ? "active" : ""}`} onClick={() => navigate(APP_ROUTES.contact)}>Contact</span>
                </div>

                <div className={`routes-container mobile-container d-flex flex-column d-sm-none gap-3 ps-4  align-items-start justify-content-center ${expand ? "navbar-expanded" : ""}`}>
                    <span className={`font-size-24 pt-5 font-mst-b pointer main-dark-green-color ${location.pathname === APP_ROUTES.homepage ? "active" : ""}`} onClick={() => handleNavigate(APP_ROUTES.homepage)}>Acasă</span>
                    <span className={`font-size-24 font-mst-b pointer main-dark-green-color ${location.pathname === APP_ROUTES.about ? "active" : ""}`} onClick={() => handleNavigate(APP_ROUTES.about)}>Despre</span>
                    <span className={`font-size-24 mb-5 font-mst-b pointer main-dark-green-color ${location.pathname === APP_ROUTES.contact ? "active" : ""}`} onClick={() => handleNavigate(APP_ROUTES.contact)}>Contact</span>
                </div>

                <div className={`interaction-container d-flex d-sm-none ${expand ? "cross" : ""}`} onClick={() => setExpand(!expand)}>
                    <div className='l1'></div>
                    <div className='l2'></div>
                    <div className='l3'></div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar