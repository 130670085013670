import React from 'react'
import { useNavigate } from 'react-router-dom';
import "./Errorpage.scss";

const Errorpage = () => {
  const navigate = useNavigate();
  return (
    <div className='page-not-found'>
      Pagina cautată nu a fost gasită!
      <span className='back-home' onClick={() => navigate("/")}>Înapoi Acasă</span>
    </div>
  )
}

export default Errorpage