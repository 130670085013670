import React, { useEffect } from 'react'
import "./AboutUs.scss"
import { Helmet } from 'react-helmet'
import Hero from './Hero/Hero'
import History from './History/History'
import Team from './Team/Team'
import Misiune from './Misiune/Misiune'
import FAQ from './FAQ/FAQ'

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>TAN | Despre</title>
      </Helmet>
      <div id="aboutus">
        <Hero />
        <History />
        {/* <Team /> */}
        <FAQ />
      </div>
    </>
  )
}

export default AboutUs