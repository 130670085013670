import React from 'react'
import "./Footer.scss";
import { Col, Row } from 'react-bootstrap';
import { BsFacebook as FBICO } from "react-icons/bs"
import { BsInstagram as InstagramICO } from "react-icons/bs"
import { BsWhatsapp as WAPPICO } from "react-icons/bs"
import { MdDoubleArrow as DoubleArrows } from "react-icons/md"
import { AiOutlineMail as EmailICo } from "react-icons/ai"
import { BsTelephoneForward as PhoneIco } from "react-icons/bs"
import { BiMap as MapIco } from "react-icons/bi"

import MainLogo from "../../../Assets/png/logo1.png"
import { QRCodeSVG } from 'qrcode.react';
import { APP_ROUTES } from '../../../Constants/constants';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();

    return (
        <footer id="footer">
            <div className='app-container py-3 d-flex gap-3 align-items-center justify-content-between w-100'>
                <Row className=' h-100 my-auto w-100'>
                    <Col className=' h-100'>
                        <div className=' h-100 d-flex align-items-start justify-content-center flex-column'>
                            <div className='main-logo'>
                                <img src={MainLogo} alt="logo" className='img-fluid main-logo' />
                            </div>
                            <div className='mt-3 main-dark-green-color main-description font-size-16 font-msb-r line-height-1'>
                                Firma TanTranschem își desfășoară activitatea de distribuire îngrășăminte chimice în județul Ialomița, orașul Slobozia din anul 2008  până în prezent. Având o gamă variată de produse precum azotat de amoniu, uree, complexe poate satisface piața cu servicii de calitate.

                            </div>
                            {/* <div className='mt-3 social-media-icons d-flex align-items-center justify-content-center gap-3'>
                                <button>
                                    <FBICO />
                                </button>
                                <button>
                                    <InstagramICO />
                                </button>
                                <button>
                                    <WAPPICO />
                                </button>
                            </div> */}
                            <QRCodeSVG
                                size={90}
                                value={"Contact: Tanase Ion; Tel: 0725867307; email: tan.transcom@gmail.com"}
                                className="qr-code mt-3"
                            />

                        </div>
                    </Col>
                    <Col>
                        <Row className='h-100'>
                            <Col>
                                <div className='h-100 d-flex align-items-start justify-content-center flex-column'>
                                    <div className='navigations d-flex flex-column gap-0 gap-sm-2 align-items-start justify-content-center'>
                                        <h4 className='mt-3 d-flex main-dark-green-color align-items-center justify-content-center gap-0 gap-sm-2 font-size-24 font-msb-r'>
                                            Vezi și..
                                        </h4>
                                        <span className='d-flex align-items-center justify-content-center gap-2 font-size-24 font-msb-r' onClick={() => navigate(APP_ROUTES.homepage)}>
                                            <DoubleArrows />
                                            Acasă
                                        </span>
                                        <span className='d-flex align-items-center justify-content-center gap-2 font-size-24 font-msb-r' onClick={() => navigate(APP_ROUTES.about)}>
                                            <DoubleArrows />
                                            Despre
                                        </span>
                                        <span className='d-flex align-items-center justify-content-center gap-2 font-size-24 font-msb-r' onClick={() => navigate(APP_ROUTES.contact)}>
                                            <DoubleArrows />
                                            Contact
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='h-100 d-flex align-items-start justify-content-center flex-column'>
                                    <div className='interactions d-flex flex-column gap-0 gap-sm-2 align-items-start justify-content-center'>
                                        <h4 className='mt-3 d-flex main-dark-green-color align-items-center justify-content-center gap-0 gap-md-2 font-size-24 font-msb-r'>
                                            Contact
                                        </h4>
                                        <a href={"mailto:tan.transcom@gmail.com"} className='d-flex align-items-center justify-content-center gap-2 font-size-24 font-msb-r'>
                                            <EmailICo />
                                            tan.transcom@gmail.com
                                        </a>
                                        <a href={"tel:0725867307"} className='d-flex align-items-center justify-content-center gap-2 font-size-24 font-msb-r'>
                                            <PhoneIco />
                                            0725867307
                                        </a>
                                        <a href={"https://www.google.com/maps/search/DN+21,+km+4+,+920120/@44.4169071,26.1324694,12z/data=!3m1!4b1"} target="_blank" className='d-flex align-items-center justify-content-center gap-2 font-size-24 font-msb-r'>
                                            <MapIco />
                                            DN 21, km 4 , 920120
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </footer>
    )
}

export default Footer