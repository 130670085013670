import React from 'react'
import "./Contact.scss";
import { Col, Row } from 'react-bootstrap';
import { AiOutlineMail as EmailICo } from "react-icons/ai"
import { BsTelephoneForward as PhoneIco } from "react-icons/bs"
import { BiMap as MapIco } from "react-icons/bi"
import { AiOutlineUser as UserIco } from "react-icons/ai"
import { AiOutlineUserAdd as UserIcoAdd } from "react-icons/ai"
import { AiOutlineClockCircle as ClockIco } from "react-icons/ai"
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>TAN | Contact</title>
      </Helmet>
      <div id="contact" className='mt-5 d-flex align-items-center justify-content-center'>
        <div className='app-container my-auto font-mst-r d-flex align-items-center justify-content-center'>
          <div className='w-100 d-flex align-items-center justify-content-center'>

            <Row className='w-100' xs={1} sm={1} md={1} lg={2}>
              <Col className='d-flex align-items-center justify-content-center mb-3 mb-lg-0'>
                <div className="parent mx-auto">
                  <div className="mapouter"><div className="gmap_canvas"><iframe width="400" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=DN%2021,%20km%204%20,%20920120&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                </div>
              </Col>
              <Col>
                <div className='wrapper h-100 d-flex align-items-start justify-content-center gap-2 flex-column'>
                  <span className='font-mst-b font-size-18'><UserIco />Nume: Tanase Ion</span>
                  <span className='font-mst-b font-size-18'><PhoneIco />Telefon: <a href="tel:0725867307">0725867307</a></span>
                  <span className='font-mst-b font-size-18'><EmailICo />Email: <a href="mailto:tan.transcom@gmail.com">tan.transcom@gmail.com</a></span>
                  <span className='font-mst-b font-size-18' onClick={() => window.open("https://www.google.com/maps/search/DN+21,+km+4+,+920120/@44.4169071,26.1324694,12z/data=!3m1!4b1")}><MapIco />Adresa: DN 21, km 4 , 920120</span>
                  <span className='font-mst-b font-size-18'><UserIcoAdd />Persoana de contact: Tanase Ion</span>
                  <span className='font-mst-b font-size-18'><ClockIco />Program:</span>
                  <span className='font-mst-b font-size-18'>•  Luni → Vineri → 09:00 - 17:00</span>
                  <span className='font-mst-b font-size-18'>•  Sâmbătă → 09:00 - 13:00</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact