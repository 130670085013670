import React from 'react'
import "./Oferta.scss"
import { Col, Row } from 'react-bootstrap'
import Fade from 'react-reveal/Fade';
import Poza1 from "./Assets/11.PNG"
import Poza2 from "./Assets/22.PNG"
import Poza3 from "./Assets/33.PNG"
import Poza4 from "./Assets/44.PNG"
import Poza5 from "./Assets/55.PNG"
import Info1 from "./Assets/1.png"
import Info2 from "./Assets/2.png"
import Info3 from "./Assets/3.PNG"
import Info4 from "./Assets/4.PNG"
import Info5 from "./Assets/5.PNG"

const Oferta = () => {
    return (
        <section id="homepage-offer" className='section-space'>
            <div className='app-container'>
                <h2 className='font-size-64 font-mst-b mb-4'>Produsele noastre</h2>
                <div className='d-flex align-items-center justify-content-center flex-column gap-4'>

                    <Fade bottom>
                        <div className='produs'>
                            <Row xs={1} sm={1} md={2} lg={2}>
                                <Col>
                                    <div className='column-wrapper h-100 p-4 d-flex align-items-center align-items-md-start justify-content-center flex-column'>

                                        <h2 className='font-size-48 font-mst-b mb-4 main-dark-green-color'>Nitratul (Azotatul) de amoniu </h2>
                                        <span className='font-size-16 font-mst-b mb-4 main-dark-green-color text-center text-md-start'> - are o concentrație ridicată de azot, efect rapid și este adecvat pentru toate culturile. Conține o jumătate de cantitate de azot sub formă de nitrat și jumătate sub formă de amoniu. <br /> Recomandări de utilizare pentru :
                                        </span>
                                        <div className='d-flex align-items-center justify-content-center gap-3 flex-wrap'>
                                            <div className='item-container d-flex align-items-center justify-content-between flex-column gap-2'>
                                                <img src={Info1} alt="helpful" className='img-fluid' />
                                                <span className='font-size-16 font-mst-r text-center lh-1'>Grâu de iarnă</span>
                                            </div>
                                            <div className='item-container d-flex align-items-center justify-content-between flex-column gap-2'>
                                                <img src={Info2} alt="helpful" className='img-fluid' />
                                                <span className='font-size-16 font-mst-r text-center lh-1'>Orz de iarnă</span>
                                            </div>
                                            <div className='item-container d-flex align-items-center justify-content-between flex-column gap-2'>
                                                <img src={Info3} alt="helpful" className='img-fluid' />
                                                <span className='font-size-16 font-mst-r text-center lh-1'>Porumb - boabe</span>
                                            </div>
                                            <div className='item-container d-flex align-items-center justify-content-between flex-column gap-2'>
                                                <img src={Info4} alt="helpful" className='img-fluid' />
                                                <span className='font-size-16 font-mst-r text-center lh-1'>Rapiță de primăvară
                                                </span>
                                            </div>
                                            <div className='item-container d-flex align-items-center justify-content-between flex-column gap-2'>
                                                <img src={Info5} alt="helpful" className='img-fluid' />
                                                <span className='font-size-16 font-mst-r text-center lh-1'>Orz de primăvară
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='p-4 pt-0 pt-md-4 h-100 d-flex align-items-center justify-content-center'>
                                        <img src={Poza1} alt="product img" className='img-fluid' />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className='produs'>
                            <Row xs={1} sm={1} md={2} lg={2}>
                                <Col>
                                    <div className='p-4 pb-0 pb-md-4 h-100 d-flex align-items-center justify-content-center'>
                                        <img src={Poza2} alt="product img" className='img-fluid' />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='column-wrapper h-100 p-4 d-flex align-items-center align-items-md-start justify-content-center flex-column'>

                                        <h2 className='font-size-48 font-mst-b mb-4 main-dark-green-color'>DAP 18 46 complexe </h2>
                                        <span className='font-size-16 font-mst-b mb-4 main-dark-green-color text-center text-md-start'>
                                            - este un fertilizant complex, granulat complex, aplicabil pe toate solurile, la toate plantele, recomandat în special pe solurile slab aprovizionate în fosfor.
                                            <br />Țară de origine : Maroc
                                            <br />Distribuitor - TanTranschem
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className='produs'>
                            <Row xs={1} sm={1} md={2} lg={2}>
                                <Col>
                                    <div className='column-wrapper h-100 p-4 d-flex align-items-center align-items-md-start justify-content-center flex-column'>

                                        <h2 className='font-size-48 font-mst-b mb-4 main-dark-green-color'>Ureea granulată </h2>
                                        <span className='font-size-16 font-mst-b mb-4 main-dark-green-color text-center text-md-start'>
                                            - este un îngrășământ pe bază de azot. Granulele sunt compacte iar acest produs se solubilizează încet, împiedicând pierderea în atmosferă a azotului.
                                            <br />Țară de origine : Egipt
                                            <br />Distribuitor : TanTranschem
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='p-4 pt-0 pt-md-4 h-100 d-flex align-items-center justify-content-center'>
                                        <img src={Poza3} alt="product img" className='img-fluid' />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className='produs'>
                            <Row xs={1} sm={1} md={2} lg={2}>
                                <Col>
                                    <div className='p-4 pb-0 pb-md-4 h-100 d-flex align-items-center justify-content-center'>
                                        <img src={Poza4} alt="product img" className='img-fluid' />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='column-wrapper h-100 p-4 d-flex align-items-center align-items-md-start justify-content-center flex-column'>

                                        <h2 className='font-size-48 font-mst-b mb-4 main-dark-green-color'>NPK 20:20:0 +14S </h2>
                                        <span className='font-size-16 font-mst-b mb-4 main-dark-green-color text-center text-md-start'>
                                            - este un îngrășământ pe bază de Azot (N) și Fosfor (P), ce asigură un aport echilibrat de nutrienți necesari pentru creșterea calității și cantității producției agricole.
                                            <br />Țară de origine : Rusia
                                            <br />Distribuitor : TanTranschem
                                            <br />Recomandări de aplicare : pe semănăturile de toamnă favorizează dezvoltarea și înfrățirea, chiar și în condiții climatice nefavorabile; poate fi utilizat ca îngrășământ cu aplicare fazială, la fertilizări de bază dar și concomitent semănatului și plantatului.

                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className='produs w-100'>
                            <Row xs={1} sm={1} md={2} lg={2}>
                                <Col>
                                    <div className='column-wrapper h-100 p-4 d-flex align-items-center align-items-md-start justify-content-center flex-column'>

                                        <h2 className='font-size-48 font-mst-b mb-4 main-dark-green-color'>NPK 8:15:15+3%Ca+9%S</h2>
                                        <span className='font-size-16 font-mst-b mb-4 main-dark-green-color text-center text-md-start'>Țară de origine : Serbia
                                            <br />Distribuitor : TanTranschem
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='p-4 pt-0 pt-md-4 h-100 d-flex align-items-center justify-content-center'>
                                        <img src={Poza5} alt="product img" className='img-fluid' />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}

export default Oferta